/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from "prop-types"
import React from "react"
import "./layout.css"
import SideBar from "./sidebar"

const Layout = ({ children }) => {
  return (
    <>
    <div className="flex flex-wrap">
      <div className="w-full md:w-auto bg-gray-900 min-h-screen flex flex-col relative">
        <SideBar />
      </div>
      <main className="flex flex-1 flex-col bg-gray-400 pl-4 pr-4 pt-4 pb-8 min-h-screen">{children}</main>
    </div>      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
