import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";

const SideBar = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "photo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="pl-4 pr-4 pt-4 min-h-screen flex flex-col flex-1 flex-grow">
      <div className="text-white mb-2 md:mb-16">
        <p className="uppercase font-light mb-0" style={{ fontFamily: 'Open Sans' }}>Solutions Architect | FullStack Developer</p>
      </div>
      <div className="text-center">
        <Link to="/">
          <Img fluid={data.placeholderImage.childImageSharp.fluid} style={{ maxWidth: '200px' }} className="rounded-full mx-auto my-4"></Img>
          <h2 className="text-white">Diogo Moreira Bispo</h2>
        </Link>
      </div>
      <div className="text-white font-sans flex flex-col">
        <Link to="blog#page_title" className="mx-auto text-gray-500 uppercase hover:underline border rounded p-1 border-gray-500">blog</Link>
        <ul className="mx-auto mt-4 divide-y divide-gray-600">
          <li className="mb-2"><FontAwesomeIcon icon={faCaretRight} /> <Link to="blog/tag/Gatsby#page_title">Gatsby</Link></li>
          <li><FontAwesomeIcon icon={faCaretRight} /> <Link to="blog/tag/Front-end#page_title">Front-end</Link></li>
        </ul>
      </div>
      <div className="flex flex-wrap flex-1 flex-grow">
        <div className="self-end mx-auto text-white p-4" style={{ height: 'fit-content' }}>
          <h3 className="mb-4">CONNECT</h3>
          <a href="https://github.com/dbispo/"><FontAwesomeIcon icon={faGithub} size="3x" className="mr-4" /></a>
          <a href="https://www.linkedin.com/in/diogobispo/"><FontAwesomeIcon icon={faLinkedin} size="3x" className="mr-4" /></a>
          <Link to="/contact#contact_form"><FontAwesomeIcon icon={faEnvelope} size="3x" /></Link>
        </div>
      </div>
    </div>
  )
}
export default SideBar